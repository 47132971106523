<template>
  <div>
    <h1 class="display-1">Select Van</h1>
    <v-divider></v-divider>
    <v-list v-if="!loading">
      <v-list-item-group v-model="selectedVan" color="primary">
        <v-list-item two-line v-for="(item, i) in vans" :key="i">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{
              item.brandModel
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.customId }}</v-list-item-subtitle>
            <v-list-item-subtitle :class="{ 'green--text' : item.vanChecksOut, 'red--text': !item.vanChecksOut }">
            {{ item.timings }}
          </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>

import * as dayjs from 'dayjs'
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default {

  props: {
    fetchVan: {
      type: Function,
      required: false,
    },
    date: {
      type: String,
      required: false
    },
  },

  data: () => ({
    vans: [],
    selectedVan: {},
    vanChecksOut: false,
    selectedDriver: null,
    loading: true
  }),

  watch: {
    selectedVan(val) {
      console.log(this.vans[val].vanChecksOut)
      if(!this.vans[val].vanChecksOut) {
        return null
      } else {
        const tripWeekDay = Number(dayjs(this.date).format('d'))
        let driverId = this.vans[val].macroPlanning[tripWeekDay].driverId
        this.$store.dispatch('getDriverById', { id: driverId }).then(doc => {
          this.fetchVan(this.vans[val], doc)
        })
      }
      
    }
  },

  created() {

    // const tripWeekDay = Number(dayjs(this.tripDateTime).format('d'))

    // let vanTime = this.vanMacro.get(tripWeekDay).on

    this.$store.dispatch("getVans").then(async (vans) => {
      this.vans = vans;
      for(let i=0; i<this.vans.length; i++) {
        let timeData = await this.getWeekDayTiming(this.vans[i].macroPlanning)
        this.vans[i].vanChecksOut = timeData.condition
        this.vans[i].timings = timeData.data
      }
      console.log(this.vans)
      this.loading = false
    })
  },

  methods: {
    async getWeekDayTiming(item, index) {

      const tripWeekDay = Number(dayjs(this.date).format('d'))
      // Getting time of trip
      const triptime = dayjs(this.date).format('HH:mm')

      let vanTime = item[tripWeekDay].time

      if(!vanTime) {
        return Promise.resolve({ condition: false, data: 'N/A' })
      }

      // Checking if the time falls between the schedule of driver and van
      if(dayjs(`2022-04-20 ${triptime}`).isBetween(dayjs(`2022-04-20 ${vanTime.split('-')[0]}`), dayjs(`2022-04-20 ${vanTime.split('-')[1]}`), 'minute')) {

        console.log(vanTime, 'Yay')

        return Promise.resolve({ condition: true, data: vanTime })
      } else {

        console.log(vanTime, 'Nay')

        return Promise.resolve({ condition: false, data: 'N/A' })

      }
      
    }
  },
};
</script>

<style></style>
